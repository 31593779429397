import React from 'react'
import './Project.css'
import {FaSearchPlus } from "react-icons/fa"
import {BsLink45Deg} from "react-icons/bs"

const Project = () => {
  return (
    <div className="container-fluid project_wrapper " id="project">
        <div className="title">
            <h1 className='title_head project_title'>PROJECTS</h1>
            <p className='title_para project-para'>My Works</p>
            </div>
        {/* HTML AND CSS  */}
        <div className="container">
          <h2 style={{textAlign:"center",color:"aqua",marginBottom:"20px"}} className='project_head'>HTML AND VANILLA CSS PROJECTS</h2>
          {/* html and vanilla css projects  */}
          <div className="row row-project">
            <div className="col-md-4 col-sm-12 img-contain">
              <img src="img/coffee.png" alt=""  className='img-html'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/coffee.png" target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="https://sakshig21.github.io/CofeeSite/" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Coffee Site</h4>
             </div>

              </div>
            </div>
            <div className="col-md-4 col-sm-12 img-contain">
              <img src="img/spa.png" alt=""  className='img-html'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/spa.png" target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="https://sakshig21.github.io/CofeeSite/" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Spa center Site</h4>
             </div>

              </div>
            </div>
            <div className="col-md-4 col-sm-12 img-contain">
              <img src="img/proinsure.png" alt=""  className='img-html'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/proinsure.png" target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="https://sakshig21.github.io/CofeeSite/" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Proinsure Site</h4>
             </div>

              </div>
            </div>
          </div>
          {/* html and bootstrap projects  */}
          
          


        </div>
             {/* HTML AND BOOTSTRAP  */}
        <div className="container">
          <h2 style={{textAlign:"center",color:"aqua",marginBottom:"20px"}} className='project_head'>HTML AND BOOTSTRAP PROJECTS</h2>
          {/* html and vanilla css projects  */}
          <div className="row row-project">
            <div className="col-md-6 col-sm-12 img-contain">
              <img src="img/food.png" alt=""  className='img-html'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/food.png" target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="https://food-dpa.pages.dev/" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Food Site</h4>
             </div>

              </div>
            </div>
            <div className="col-md-6 col-sm-12 img-contain">
              <img src="img/socialmedia.png" alt=""  className='img-html'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/socialmedia.png "  target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="https://socialmedia-623.pages.dev/" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Digital Marketing Site</h4>
             </div>

              </div>
            </div>
            
          </div>
          {/* html and bootstrap projects  */}
          
          


        </div>

        {/* REACT PROJECTS  */}
        <div className="container">
          <h2 style={{textAlign:"center",color:"aqua",marginBottom:"20px"}} className='project_head'>REACT PROJECTS</h2>
          {/* html and vanilla css projects  */}
          <div className="row row-project">
            <div className="col-md-4 col-sm-12 img-contain ">
              <img src="img/todo.png" alt=""  className='img-html contain-react'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/todo.png" target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="https://todolist-c6g.pages.dev/" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Todolist</h4>
             </div>

              </div>
            </div>
            <div className="col-md-4 col-sm-12 img-contain">
              <img src="img/weather.png" alt=""  className='img-html contain-react'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/weather.png" target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="https://weatherapp-a55.pages.dev/" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Weather </h4>
             </div>

              </div>
            </div>
            <div className="col-md-4 col-sm-12 img-contain">
              <img src="img/gym.png" alt=""  className='img-html'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/gym.png" target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="https://gymwebsite-2gg.pages.dev/" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Gym Site</h4>
             </div>

              </div>
            </div>
          </div>
          {/* html and bootstrap projects  */}
          <div className="container">
          <h2 style={{textAlign:"center",color:"aqua",marginBottom:"20px"}} className='project_head'>UI-UX PROJECT</h2>
          {/* html and vanilla css projects  */}
          <div className="row row-project">
            <div className="col-md-6 col-sm-12 img-contain">
              <img src="img/complete.jpg" alt=""  className='img-html'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/wireframe.png" target='_blank'>
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="cv/ui-ux portfolio.pdf" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>Research</h4>
             </div>

              </div>
            </div>
            <div className="col-md-6 col-sm-12 img-contain">
              <img src="img/UI.jpg" alt=""  className='img-html'/>
              <div className="hover-contain">
                <div className="box-1"></div>
             <div className="hover-btn">
              <a href="img/UI.jpg"target='_blank' >
               <FaSearchPlus className='icon-hover'/>
              </a>
              <a href="cv/UI DESIGN.pdf" target='_blank'>< BsLink45Deg className='icon-hover'/></a>
             </div>
             <div className="hover-text">
              <h4>UI Design</h4>
             </div>

              </div>
            </div>
            
          </div>
          {/* html and bootstrap projects  */}
          
          


        </div>
          


        </div>
            

        

            
      

    </div>
  )
}

export default Project